$success: #548e36;
$primary: #03793E; // PN Green
$danger: #EE4266;
$secondary: #F5E748; // PN Yellow
$light: #D8D8D8;
$grey: #adadad;
$body-color: #000000;
$warning: #f5e747;
$info: #EE4266;
$orange: #f58238;
$purple: #993dbb;

// Headings
h1, .h1 {
    font-family: 'Multima', sans-serif;
}

$display-font-weight: 700;
$headings-font-weight: 700;

// https://getbootstrap.com/docs/5.2/utilities/spacing/#sass
$spacer: 1.8rem;

// Border utilities overrides
// https://getbootstrap.com/docs/5.2/utilities/borders/#sass-variables
$border-radius-lg: 2em;
$border-radius-sm: 2em;
$border-radius: 2em;

// Input overrides
// https://getbootstrap.com/docs/5.2/forms/form-control/#variables
$input-font-size-lg: 1rem;
$input-padding-x-lg: 2rem;
$input-padding-y-lg: 1rem;
$input-btn-font-size-lg: 1rem;
$input-btn-padding-y-lg: 1rem;
$input-btn-padding-x-lg: 3.5rem;
$input-font-weight: 300;

// Form control overrides
// https://getbootstrap.com/docs/5.0/forms/form-control/#variables
$form-label-font-weight: 500;
$form-text-font-weight:  300;

// Button overrides
// https://getbootstrap.com/docs/5.2/components/buttons/#sass-variables
$btn-font-weight: bold;

// Modal overrides
// https://getbootstrap.com/docs/5.0/components/modal/#variables
$modal-content-border-width: 0;

$popover-max-width: 400px;
$popover-arrow-width:               0rem;
$popover-arrow-height:              0rem;

@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/utilities";

$utilities: map-merge(
  $utilities,
  (
    "width": map-merge(
      map-get($utilities, "width"),
      (
        values: map-merge(
          map-get(map-get($utilities, "width"), "values"),
          (30: 30%),
        ),
      ),
    ),
    "background-color": (
      property: background-color,
      class: bg,
      values: map-merge(
        $theme-colors,
        (
          "orange": $orange,
          "purple": $purple,
        )
      )
    ),
    "border-color": (
      property: border-color,
      class: border,
      values: map-merge(
        $theme-colors,
        (
          "orange": $orange,
          "purple": $purple,
        )
      )
    )
  ),
);

@import "../node_modules/bootstrap/scss/bootstrap";
@import "./css/typography";

@include media-breakpoint-down(md) {
    html, body, #root {
        font-size: 13px;
    }
}

html, body, #root {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.form-control-lg {
    border: none;
}

// Button styles
.btn.btn-primary {
    color: $secondary;
}
.btn.btn-secondary {
    color: $primary;
}

.btn.muted.btn-secondary {
    background-color: lighten($secondary, 20%);
    border-color: lighten($secondary, 20%);
    font-weight: 300;
}

// Form progress styles
.pineapple-form-progress {
  color: $body-color;
  font-size: 0.8rem;
  padding-top: 2.5rem;
  padding-bottom: 4rem;

  .badge {
      font-size: 1rem;
      padding: 0.5rem 0.75rem;
      border: 2px solid $light;
      color: $body-color;
      background-color: $gray-100;
      position: relative;
      z-index: 2;
      margin-bottom: 1rem;
  }

  hr {
    opacity: 1;
    border: 1px solid $light;
    position: absolute;
    top: -0.9rem;
    left: $spacer;
    right: $spacer;
    z-index: 1;
  }
}

.pineapple-form-progress .active  {
  color: $primary;
  font-weight: bolder;

  .badge {
      border-color: $primary;
      color: $secondary;
      background-color: $primary;
  }
}

// Pineapple cards

.pineapple-card {
  flex: 0 0 auto;
  max-width: 335px;
}

// Chargebee form styles
.form-control iframe {
  padding: 0.45em !important;
  height: 32px !important;
}

 /* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.navLeft {
  color: white;
}

.auto-complete {
  width: 100%;
}

@media (max-width: 800px) {
  .auto-complete {
    width: 100%;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height:70vh;
}

.center-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-with-radius {
  border-radius: 30px;
  height: 48px;
}

.dgtek-input-wrapper {
  justify-content: center;
  align-items: center;
  top: 8px;
  margin: 0;
  width: 100%;
  max-width: 820px;
  height: 48px;
  border-radius: 4px;
  background: transparent;
  outline: none;
}

.subheader {
  font-size: 26px;
}

.verticaltext{
  transform: rotate(90deg);
  transform-origin: left top 0;
}

.display-2 {
  font-size: 48px !important;
}

.display-3 {
  font-size: 40px !important;
}

.display-6 {
  font-size: 30px !important;
}

 input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  padding: 10px;
}

input[type=radio] {
    border: 1px solid #979797;
    width: 20px;
    height: 20px;
}

.time-picker {
    background: transparent;
    border: 1px solid #000;
    border-radius: 28px;
    font-size: 16px;
    color: #000;
    letter-spacing: 0.3px;
}

.time-picker:hover {
    background-color: $primary !important;
    border: 1px solid $primary;
    color: white !important;
}

.time-picker-selected {
    color: white;
    font-weight: bold;
    border: 1px solid $primary;
    border-radius: 28px;
    font-size: 16px;
    background-color: $primary;
}

.text-spaced {
    font-size: 16px;
    color: #000;
}

.time-spinner {
  display: flex;
  justify-content: center;
  height:20vh;
}

// Hubspot styling


.hs-form {
    h1, h2 {
        margin-bottom: $spacer * 0.75;
        color: $primary;
        text-transform: lowercase;
    }
    h1:first-letter, h2:first-letter {
        text-transform: uppercase;
    }
    fieldset {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: none !important;
    }
    .hs-main-font-element {
        width: 100%;
        margin-top: 1rem;
    }
    .hs-form-field {
        margin-bottom: $spacer * 0.5;
        flex: 1;
        label {
            font-weight: 500;
        }
        ul.hs-error-msgs {
            list-style: none;
            padding-left: 0;
            margin-left: 0;
        }
        label.hs-error-msg {
            font-weight: 300;
            color: $danger;
        }
        .input {
            margin-right: 1rem !important;
        }
        .hs-input {
            width: 100% !important;
            border-radius: $border-radius-lg;
            border: 1px solid $grey;
            padding: ($spacer * 0.25) ($spacer * 0.5);
        }
    }
}

.hs-button {
    margin-top: $spacer * 0.5;
    background-color: $primary;
    color: $secondary;
    padding: ($spacer * 0.5) ($spacer * 0.5);
    border-radius: $border-radius-lg;
    border: 2px solid $primary;
    width: 100%;
    font-size: 1.1rem;
}

.hs-button:hover {
    background-color: darken($primary, 2.5%);
    border-color: darken($primary, 2.5%);
}

.nav1-button {
    background-color: #fff;
    color: #000;
    border-radius: $border-radius-lg;
    border: 2px solid #000;
    width: 100%;
    font-size: 1.1rem;
    transition-duration: 0.4s;
}

.nav1-button:hover  {
    background-color: #c3c3c3 !important;
    border: 2px solid #000 !important;
}

.nav1-button:focus {
    background-color: #c3c3c3 !important;
    border: 2px solid #000 !important;
}

.strikethrough {
  position: relative;
}

.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 40%;
  right: 0;
  border-top: 3px solid;
  border-color: $info;
  -webkit-transform:rotate(-25deg);
  -moz-transform:rotate(-25deg);
  -ms-transform:rotate(-25deg);
  -o-transform:rotate(-25deg);
  transform:rotate(-25deg);
}
