@font-face {
  font-family: 'General Sans';
  src: url("../fonts/GeneralSans-Light.woff2") format("woff2");
  font-weight: light;
  font-style: normal;
}

@font-face {
  font-family: 'General Sans';
  src: url("../fonts/GeneralSans-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'General Sans';
  src: url("../fonts/GeneralSans-Italic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'General Sans';
  src: url("../fonts/GeneralSans-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'General Sans';
  src: url("../fonts/GeneralSans-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Multima';
  src: url("../fonts/Multima-ExtraBold.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}

html, body {
  font-family: 'General Sans', sans-serif !important; 
  font-size: 16px;
}

:root {
    --bs-font-sans-serif: 'General Sans', sans-serif !important;
}

h1, .h1 {
  font-size: 2.9rem;
}
