.toggle {
  --width: 180px;
  --height: calc(var(--width) / 3);
  --toggle-height: calc(var(--width) / 3.4);


  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: var(--height);
  cursor: pointer;
}

.toggle input {

  display: none;
}

.toggle .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--height);
  background-color: #7c7c7c;
  transition: all 0.4s ease-in-out;
}

.toggle .slider::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  margin: 3px;
  width: calc(var(--toggle-height));
  height: calc(var(--toggle-height));
  border-radius: calc(var(--toggle-height) / 2);
  background-color: #fafafa;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  transition: all 0.4s ease-in-out;
}

.toggle input:checked+.slider {
  background-color: #03793e;
}

.toggle input:checked+.slider::before {
  transform: translateX(calc(var(--width) - var(--height)));
}

.toggle .labels {
  position: absolute;
  top: 16px;
  left: 50px;
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-weight: bold;
  transition: all 0.4s ease-in-out;
}

.toggle .labels::after {
  content: attr(data-off);
  position: absolute;
  right: 100px;
  color: #fff;
  opacity: 1;
  transition: all 0.4s ease-in-out;
}

.toggle .labels::before {
  content: attr(data-on);
  position: absolute;
  left: 5px;
  color: #f5e748;
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.toggle input:checked~.labels::after {

  opacity: 0;
}

.toggle input:checked~.labels::before {
  opacity: 1;
}
