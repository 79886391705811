.toggle-container {
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
}

.step-number {
  background-color: #7c7c7c; /* Adjust color if necessary */
  color: white;
  border-radius: "200px";
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.slider-toggle {
  position: relative;
  width: 340px; /* Adjust width if necessary */
  height: 60px; /* Adjust height if necessary */
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slider {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.slider.popular-plans {
  background-color: #7c7c7c;

}

.slider.all-plans {
  background-color: #7c7c7c;
}

.toggle-thumb {
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: #03793e;
  border-radius: 50px;
  transition: left 0.3s ease;
}

.slider.popular-plans .toggle-thumb {
  left: 0;
}

.slider.all-plans .toggle-thumb {
  left: 50%;
}

.toggle-label {
  width: 50%;
  text-align: center;
  line-height: 40px; /* Adjust line-height to center text vertically */
  font-weight: bold;
  z-index: 1;

  font-size: 20px;
  font-weight: bold;
}

.popular-plans-label {
  position: absolute;
  left: 0;
  color: white;
}

.all-plans-label {
  position: absolute;
  right: 0;
  color: #f5e748;
}

